import React from 'react';
import { WHO_WE_ARE_CONTENT } from "../../constants";
import RetroModal from "../RetroModal";
import RetroCardForList from "../RetroCardForList";


interface Props {
  isOpen: boolean;
  onClose: () => void;
  initialIndex?: number;
}

const WhoWeAre: React.FC<Props> = ({ initialIndex = 0, isOpen, onClose }) => {
  return (
    <RetroModal isOpen={isOpen} onClose={onClose}>
      <RetroCardForList
        initialIndex={initialIndex}
        title="Who we are"
        values={WHO_WE_ARE_CONTENT}
        isOneItemPerPage={true}
        onClose={onClose}
      />
    </RetroModal>
  );
};

export default WhoWeAre;
