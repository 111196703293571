// RetroButton.tsx
import React from 'react';

type RetroButtonProps = {
  label: string;
  onClick?: () => void;
  hidden?: boolean;
  type?: "button" | "submit" | "reset";
  className?: string;
};

const RetroButton: React.FC<RetroButtonProps> = ({label, onClick, hidden, type, className}) => {
  if (hidden) {
    return null;
  }
  return (
    <button
      type={type}
      className={`font-pixel text-xs pointer-events-auto w-full bg-black bg-opacity-80 text-white font-bold py-2 px-4 border border-blue-500 hover:border-blue-700 rounded-lg shadow retro-glow transform transition duration-150 ease-in-out hover:scale-105 active:scale-95 ${className}`}
      onClick={onClick}
    >
      {label}
    </button >
  );
};

export default RetroButton;

