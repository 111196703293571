export const PACKAGES = [
  {
    title: "Blockchain Consulting",
    description: [
      "Helping businesses unlock the full potential of blockchain and Web3 solutions.",
      "We guide you through blockchain’s potential and its best applications for your business.",
      "We offer R&D services, feasibility studies to help you validate your ideas.",
      "We help you implement blockchain solutions for greater security, transparency, and efficiency."
    ]
  },
  {
    title: "Craft Your Product",
    description: [
      "From idea to launch, we turn your vision into a market-ready product.",
      "We work with you to define your product’s scope and craft a go-to-market strategy.",
      "We try to handle everything—strategy, UX/UI, scalable architecture, and data-driven growth.",
      "We simplify the journey of product development, ensuring that your product is aligned with your business goals and user needs.",
    ]
  },
  {
    title: "DevOps Revamp",
    description: [
      "Helping startups and businesses modernize and optimize their infrastructure.",
      "We help you build a DevOps culture and implement the right tools and processes for your organization.",
      "Customized pipeline setup, cloud integration, continuous integration/continuous deployment (CI/CD), monitoring, and automated testing.",
      "We also provide ongoing support and maintenance to ensure your infrastructure is always up-to-date and secure."
    ]
  },
];
export const CORE_VALUES = [
  {
    title: "Start with the Why",
    description: "We start with purpose—ensuring every project has meaningful impact."
  },
  {
    title: "Take Initiative",
    description: "We encourage ownership and proactive problem-solving at every level."
  },
  {
    title: "Adapt and Evolve",
    description: "We see challenges as opportunities to grow and innovate."
  },
  {
    title: "Speak Up, Stronger Ideas",
    description: "Every voice matters, every voice is heard, and every idea is valued. Open communication bring out the best work."
  },
  {
    title: "Think Critically",
    description: "We try to not just follow trends—we question, analyze, and create rather than just follow instructions."
  },
  {
    title: "Decisions Rooted in Data",
    description: "We tend to call our decision -smart- by trying to ground them with real data."
  },
  {
    title: "Never Stop Growing",
    description: "Personal and professional growth are at the core of our company. We encourage continuous learning and development both at home and work."
  },
  {
    title: "Cultivate Understanding",
    description: "Understanding and respecting diverse perspectives is crucial to create a -creative- workplace."
  }
];

export const WHO_WE_ARE_CONTENT = [
  {
    title: '',
    description: [
      "We’re a small tech team helping startups and businesses build solid, scalable MVPs — with a focus on Web3.",
      "We're also here to look at your current tech and help level it up.",
    ]
  },
  {
    title: '',
    description: [
      "We like to keep things simple, stupid. We try to really understand what people need, and then build something that works — fast, solid, and easy to maintain.",
      "Whether it’s fixing your infrastructure, building an MVP, or exploring blockchain use cases, we approach each project with care and curiosity.",
      "Based in Thailand. Crafting worldwide."
    ]
  }
];
