import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import FormModal from './components/modals/FormModal';
import HowWeWorkModal from './components/modals/HowWeWorkModal';
import WhatWeDoModal from './components/modals/WhatWeDoModal';
import WhoWeAre from './components/modals/WhoWeAre';
import NavBar from "./components/NavBar";
import RetroButton from "./components/RetroButton";
import RetroLink from "./components/RetroLink";
import createScene from "./scene";


function App() {
  const [isWhoWeAreModalOpen, setIsWhoWeAreModalOpen] = useState(false);
  const [isHowWeWorkModalOpen, setIsHowWeWorkModalOpen] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isWhatWeDoModalOpen, setIsWhatWeDoModalOpen] = useState(false);
  const [isWhatWeDoModalInitialIndex, setIsWhatWeDoModalInitialIndex] = useState(0);

  const closeHowWeWorkModal = () => {
    setIsHowWeWorkModalOpen(false);
  }

  const isModalOpen = isWhatWeDoModalOpen || isFormModalOpen || isWhoWeAreModalOpen || isHowWeWorkModalOpen;

  const refContainer = useRef(null);
  useEffect(() => {
    createScene(refContainer);
    return;
  }, []);
  return (
    <div className="relative" style={{height: '100%'}}>
      <div ref={refContainer} className="h-full"/>
      <div className="absolute overflow-hidden pointer-events-none max-h-screen h-full top-0 left-0 w-full">
        <NavBar onClick={() => setIsFormModalOpen(true)}/>
        <section
          style={{height: 'calc(100vh - 10rem)'}}
          className="flex justify-center items-center px-4 sm:px-6 lg:px-8">
          {!isModalOpen && (
            <div className={"text-center justify-center items-center"}>
              <p className=" font-pixel text-xl font-bold text-white mb-4">We <RetroLink
                onClick={() => {
                  setIsWhatWeDoModalInitialIndex(1);
                  setIsWhatWeDoModalOpen(true);
                }} label={"Craft"}/> and <RetroLink label={"Automate"}
                                                    onClick={() => {
                                                      setIsWhatWeDoModalInitialIndex(2);
                                                      setIsWhatWeDoModalOpen(true);
                                                    }}/> toward <RetroLink
                label={"Web3"} onClick={() => setIsWhatWeDoModalOpen(true)}/>
              </p>
              {!isModalOpen && (<div className="mb-5">
                <p className="font-pixel text-sm font-bold text-white" style={{boxShadow: "0 0 0"}}>We design, build,
                  and automate to scale your business.</p>
              </div>)}
              <div className="mb-4 ">
                <RetroButton className={"max-w-xs"} hidden={isModalOpen} label="About Us"
                             onClick={() => setIsWhoWeAreModalOpen(true)}/>
              </div>
              <div className="mb-4 ">
                <RetroButton className={"max-w-xs"} hidden={isModalOpen} label="Our Approach"
                             onClick={() => setIsHowWeWorkModalOpen(true)}/>
              </div>
              <div className="mb-4">
                <RetroButton className={"max-w-xs"} hidden={isModalOpen} label="Our Services"
                             onClick={() => setIsWhatWeDoModalOpen(true)}/>
              </div>
              <div className="">
                <RetroButton className={"max-w-xs"} hidden={isModalOpen} label="Get in Touch"
                             onClick={() => setIsFormModalOpen(true)}/>
              </div>
            </div>
          )}
        </section>
        <WhoWeAre isOpen={isWhoWeAreModalOpen} onClose={() => setIsWhoWeAreModalOpen(false)}/>
        <HowWeWorkModal isOpen={isHowWeWorkModalOpen} onClose={closeHowWeWorkModal}/>
        <WhatWeDoModal initialIndex={isWhatWeDoModalInitialIndex} isOpen={isWhatWeDoModalOpen} onClose={() => {
          setIsWhatWeDoModalInitialIndex(0);
          setIsWhatWeDoModalOpen(false);
        }}/>
        <FormModal isOpen={isFormModalOpen} onClose={() => setIsFormModalOpen(false)}/>
      </div>
    </div>
  );
}


export default App;
